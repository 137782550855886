import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import 'dayjs/locale/th';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(buddhistEra);
dayjs.tz.setDefault('Asia/Bangkok');
dayjs.locale('th');

interface DateRenderProps {
  startDate: Dayjs | string | Date | number;
  endDate?: Dayjs | string | Date | number;
  localTh?: boolean;
}

const DateStartEndFormat = ({
  startDate: dat,
  endDate: dat2,
  localTh = true,
}: DateRenderProps) => {
  let years = 'YYYY';
  if (localTh) {
    years = 'BBBB';
  }
  const checkTypeStartDate =
    typeof dat === 'number' ? dayjs.unix(dat) : dayjs(dat);
  const checkTypeEndDate2 =
    typeof dat2 === 'number' ? dayjs.unix(dat2) : dayjs(dat2);
  if (!checkTypeStartDate.isValid()) return null;
  if (!checkTypeEndDate2.isValid()) return null;

  const renderDate = () => {
    if (checkTypeStartDate.format("MM") === checkTypeEndDate2.format("MM")) {
      return `${checkTypeStartDate.format('DD')} - ${checkTypeEndDate2.format('DD MMM ' + years)}`;
    }else{
      return `${checkTypeStartDate.format('DD MMM ')} - ${checkTypeEndDate2.format('DD MMM ' + years)}`;
    }
  };

  return renderDate();
};

export default DateStartEndFormat;
